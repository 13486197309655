<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-user'" :command="'status'"
          >Change Beautician Status</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-settings'" :command="'password-reset'"
          >Reset password</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-delete'" :command="'delete'"
          >Delete Beautician</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :beautician="beautician"
      @success="$emit('update', action.command)"
    />
  </div>
</template>

<script>
export default {
  name: "BeauticianActions",
  props: {
    beautician: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BeauticianDelete: () => import("./BeauticianDelete"),
    BeauticianStatus: () => import("./BeauticianStatus"),
    BeauticianPasswordReset: () =>  import("./BeauticianPasswordReset"),
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
    };
  },
  methods: {
    command(command) {
      this.action.component = `beautician-${command}`;
      this.action.command = command;
      setTimeout(() => {
        this.action.visibility = true;
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped></style>
